export const cloudinaryImageURLToWidth = (
  cloudinaryImageUrl: string,
  desktopWidth: number,
  mobileWidth: number
): {
  desktop: string
  mobile: string
  desktopFallback: string
  mobileFallback: string
} => {
  if (!cloudinaryImageUrl)
    return { desktop: '', mobile: '', desktopFallback: '', mobileFallback: '' }

  const urlParts = cloudinaryImageUrl.split('upload/')

  return {
    desktop: `${urlParts[0]}upload/w_${desktopWidth},f_auto/${urlParts[1]}`,
    mobile: `${urlParts[0]}upload/w_${mobileWidth},f_auto/${urlParts[1]}`,
    desktopFallback: `${urlParts[0]}upload/w_${desktopWidth},q_10,f_auto/${urlParts[1]}`,
    mobileFallback: `${urlParts[0]}upload/w_${mobileWidth},q_10,f_auto/${urlParts[1]}`,
  }
}
