import React from "react";
import styled from "styled-components";
import { useResponsive } from "../../../../utils/hooks/useResponsive";
import { cloudinaryImageURLToWidth } from "../../../../utils/cloudinary";
import { useProgressiveImage } from "../../../../utils/hooks/useProgressiveImage";
import { CSSProperties } from "react";

const ImgSt = styled.img`
  display: block;
`;

interface Props {
  desktopWidth?: number;
  mobileWidth?: number;
  cloudinaryURL: string;
  alt?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const PerformantImage = ({
  desktopWidth = 1600,
  mobileWidth = 720,
  cloudinaryURL,
  alt,
  ...rest
}: Props) => {
  const { isTablet } = useResponsive();

  const mainImageUrls = cloudinaryImageURLToWidth(
    cloudinaryURL,
    desktopWidth,
    mobileWidth
  );

  const mainImage =
    useProgressiveImage({
      src: isTablet ? mainImageUrls.desktop : mainImageUrls.mobile,
      fallbackSrc: isTablet
        ? mainImageUrls.desktopFallback
        : mainImageUrls.mobileFallback,
    }) || "/images/loading-rainbow.svg";

  return <ImgSt src={mainImage} alt={alt} {...rest} />;
};

export const preloadImage = (
  widthToPreload: number,
  cloudinaryURL: string,
  shouldPreloadFullQuality?: boolean
) => {
  const mainImageUrls = cloudinaryImageURLToWidth(
    cloudinaryURL,
    widthToPreload,
    widthToPreload
  );

  const mainImage = new Image();
  mainImage.src = shouldPreloadFullQuality
    ? mainImageUrls.desktop
    : mainImageUrls.desktopFallback;
};
