import React, { ReactNode, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../../../../utils/theme'

interface PossibleButtonStyles {
  desktop: {
    backgroundColor: string
    color: string
    borderRadius: string
    fontFamily: string
    fontSize: string
    height: string
    fontWeight?: number
    border?: string
    boxShadow?: string
    display?: string
    alignItems?: string
    webkitFontSmoothing?: string
    padding?: string

    hover: {
      backgroundColor?: string
      opacity?: number
    }
  }

  mobile: {
    backgroundColor: string
    color: string
    borderRadius: string
    fontFamily: string
    fontSize: string
    height: string
    fontWeight?: number
    border?: string
    boxShadow?: string
    display?: string
    alignItems?: string
    webkitFontSmoothing?: string
    padding?: string

    hover: {
      backgroundColor?: string
      opacity?: number
    }
  }
}

type VariantOptions = '1' | '2' | '3' | '4' | '5' | '6'

const ButtonStylesMap: Record<VariantOptions, PossibleButtonStyles> = {
  "1": {
    desktop: {
      backgroundColor: theme.colors.brandRed,
      color: theme.colors.white,
      borderRadius: "20px",
      fontFamily: " Poppins, Montserrat",
      fontSize: "0.875rem",
      height: "2.25rem",
      webkitFontSmoothing: "antialiased",
      padding: "0 1rem",
      hover: {
        backgroundColor: theme.colors.darkRed,
      },
    },
    mobile: {
      backgroundColor: theme.colors.brandRed,
      color: theme.colors.white,
      borderRadius: "20px",
      fontFamily: " Poppins, Montserrat",
      fontSize: "0.875rem",
      height: "2.25rem",
      webkitFontSmoothing: "antialiased",
      padding: "0 1rem",
      hover: {
        backgroundColor: theme.colors.darkRed,
      },
    },
  },
  "2": {
    desktop: {
      backgroundColor: theme.colors.white,
      color: theme.colors.text02,
      borderRadius: "20px",
      fontFamily: " Poppins, Montserrat",
      fontSize: "0.75rem",
      height: "2.25rem",
      display: "flex",
      alignItems: "center",
      padding: "0 1rem",

      hover: {
        opacity: 0.8,
      },
    },
    mobile: {
      backgroundColor: theme.colors.white,
      color: theme.colors.text02,
      borderRadius: "20px",
      fontFamily: " Poppins, Montserrat",
      fontSize: "0.75rem",
      height: "2.25rem",
      display: "flex",
      alignItems: "center",
      padding: "0 1rem",

      hover: {
        opacity: 0.8,
      },
    },
  },
  "3": {
    desktop: {
      backgroundColor: theme.colors.white,
      color: theme.colors.brandRed,
      borderRadius: "8px",
      fontFamily: " Poppins, Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      height: "3.875rem",
      display: "flex",
      alignItems: "center",
      padding: "0 1.5rem",

      hover: {
        opacity: 0.8,
      },
    },
    mobile: {
      backgroundColor: theme.colors.white,
      color: theme.colors.brandRed,
      borderRadius: "8px",
      fontFamily: " Poppins, Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      height: "3.875rem",
      display: "flex",
      alignItems: "center",
      padding: "0 1.5rem",

      hover: {
        opacity: 0.8,
      },
    },
  },
  "4": {
    desktop: {
      backgroundColor: theme.colors.brandRed,
      color: theme.colors.white,
      borderRadius: "5px",
      fontFamily: " Poppins, Montserrat",
      fontWeight: 500,
      fontSize: "1.0625rem",
      height: "3.875rem",
      webkitFontSmoothing: "antialiased",
      padding: "0 1rem",
      hover: {
        opacity: 0.8,
      },
    },
    mobile: {
      backgroundColor: theme.colors.brandRed,
      color: theme.colors.white,
      borderRadius: "5px",
      fontFamily: " Poppins, Montserrat",
      fontWeight: 500,
      fontSize: "1.0625rem",
      height: "3.875rem",
      webkitFontSmoothing: "antialiased",
      padding: "0 1rem",
      hover: {
        opacity: 0.8,
      },
    },
  },
  "5": {
    desktop: {
      backgroundColor: theme.colors.white,
      color: theme.colors.brandRed,
      borderRadius: "5px",
      fontFamily: " Poppins, Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      height: "3.875rem",
      border: `1px solid ${theme.colors.brandRed}`,
      boxShadow: theme.boxShadows.shadow1,
      padding: "0 1rem",
      hover: {
        backgroundColor: "#FAF7F7",
      },
    },
    mobile: {
      backgroundColor: theme.colors.white,
      color: theme.colors.brandRed,
      borderRadius: "5px",
      fontFamily: " Poppins, Montserrat",
      fontWeight: 500,
      fontSize: "1rem",
      height: "3.875rem",
      border: `1px solid ${theme.colors.brandRed}`,
      boxShadow: theme.boxShadows.shadow1,
      padding: "0 1rem",
      hover: {
        backgroundColor: "#FAF7F7",
      },
    },
  },
  "6": {
    desktop: {
      backgroundColor: "#425CF0",
      color: "#F6F8FC",
      borderRadius: "5px",
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: "1rem",
      height: "2.688rem",
      border: `1px solid #425CF0`,
      boxShadow: theme.boxShadows.shadow1,
      padding: "0 1rem",
      hover: {
        opacity: 0.8,
      },
    },
    mobile: {
      backgroundColor: "#425CF0",
      color: "#F6F8FC",
      borderRadius: "5px",
      fontFamily: "Manrope",
      fontWeight: 400,
      fontSize: "1rem",
      height: "2.688rem",
      border: `1px solid #425CF0`,
      boxShadow: theme.boxShadows.shadow1,
      padding: "0 1rem",
      hover: {
        opacity: 0.8,
      },
    },
  },
};

interface ButtonStProps {
  chosenStyles: PossibleButtonStyles
}

const ButtonSt = styled.button<ButtonStProps>`
  letter-spacing: 0.0625rem;
  width: 100%;

  ${({ chosenStyles, disabled }) => css`
    background-color: ${chosenStyles.mobile.backgroundColor};
    color: ${chosenStyles.mobile.color};
    border-radius: ${chosenStyles.mobile.borderRadius};
    font-weight: ${chosenStyles.mobile.fontWeight};
    font-family: ${chosenStyles.mobile.fontFamily};
    font-size: ${chosenStyles.mobile.fontSize};
    height: ${chosenStyles.mobile.height};
    border: ${chosenStyles.mobile.border};
    box-shadow: ${chosenStyles.mobile.boxShadow};
    display: ${chosenStyles.mobile.display};
    align-items: ${chosenStyles.mobile.alignItems};
    padding: ${chosenStyles.mobile.padding};
    -webkit-font-smoothing: ${chosenStyles.mobile.webkitFontSmoothing};

    &:hover {
      cursor: pointer;
      background-color: ${disabled ? chosenStyles.mobile.backgroundColor : chosenStyles.mobile.hover.backgroundColor};
      opacity: ${chosenStyles.mobile.hover.opacity};
    }
  `}

  ${({ theme, chosenStyles, disabled }) => `${theme.breakpoints.md} {
      background-color: ${chosenStyles.desktop.backgroundColor};
      color: ${chosenStyles.desktop.color};
      border-radius: ${chosenStyles.desktop.borderRadius};
      font-weight: ${chosenStyles.desktop.fontWeight};
      font-family: ${chosenStyles.desktop.fontFamily};
      font-size: ${chosenStyles.desktop.fontSize};
      height: ${chosenStyles.desktop.height};
      border: ${chosenStyles.desktop.border};
      box-shadow: ${chosenStyles.desktop.boxShadow};
      display: ${chosenStyles.desktop.display};
      align-items: ${chosenStyles.desktop.alignItems};
      padding: ${chosenStyles.desktop.padding};
      -webkit-font-smoothing: ${chosenStyles.desktop.webkitFontSmoothing};

      &:hover {
        cursor: pointer;
        background-color: ${disabled ? chosenStyles.desktop.backgroundColor : chosenStyles.desktop.hover.backgroundColor};
        opacity: ${chosenStyles.desktop.hover.opacity};
      }
    }`}

    ${({ disabled }) => disabled && css`
      opacity: 0.7;

      &:hover {
        cursor: not-allowed;
        opacity: 0.7;
      }
    `}
`

interface IconStProps {
  variant: VariantOptions
}

const IconSt = styled.img<IconStProps>`
  ${({ variant }) =>
    variant === '2'
      ? css`
          margin-left: 1.5rem;
        `
      : css`
          margin-left: 3rem;
          height: 30px;
        `}
`

interface Props extends React.HTMLProps<HTMLButtonElement> {
  children: ReactNode
  variant: VariantOptions
  onClick?: (e: any) => void
  styleOverrides?: {
    height?: string
    fontSize?: string
    backgroundColor?: string
    color?: string
  }
  enableI18n?: boolean
}

const ButtonCaretsMap = {
  '2': '/images/black-arrow-right.svg',
  '3': '/images/red-arrow-right.svg',
}

export const Button = ({
  variant,
  children,
  onClick,
  disabled,
  styleOverrides,
  enableI18n,
}: Props) => {
  const variantStyles = ButtonStylesMap[variant]
  const caret = (variant === '2' || variant === '3') && ButtonCaretsMap[variant]

  const styles = {
    desktop: {
      ...variantStyles.desktop,
      ...(styleOverrides || {}),
    },
    mobile: {
      ...variantStyles.mobile,
      ...(styleOverrides || {}),
    },
  }


  return (
    <ButtonSt chosenStyles={styles} onClick={(e) => disabled || !onClick ? null : onClick(e)} disabled={disabled}>
      {children}
      {caret ? <IconSt src={caret} variant={variant} /> : ''}
    </ButtonSt>
  )
}
