import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../utils/theme";

interface VariantTargetProps {
  fontWeight: number | string;
  fontFamily: string;
  lineHeight: number | string;
  fontSize: string;
  color: string;
  letterSpacing?: string;
  fontStyle?: string;
  defaultComponent?: UITypographyComponents;
}

interface TypographyProps {
  variant: VariantTargetProps;
}

export const FontsVariantsMap: Record<
  UITypographyVariants,
  VariantTargetProps
> = {
  h1: {
    fontSize: "3.125rem", // 50px
    lineHeight: "4rem", // 64px
    fontWeight: "700",
    fontFamily: " Poppins, Montserrat",
    color: theme.colors.text01,
    defaultComponent: "h1",
  },
  h2: {
    fontSize: "2.1875rem", // 35px
    lineHeight: "3rem", // 48px
    fontWeight: "400",
    fontFamily: " Poppins, Montserrat",
    color: theme.colors.text01,
    defaultComponent: "h2",
  },
  h3: {
    fontSize: "1.25rem", // 20px
    lineHeight: "3rem", // 48px
    fontWeight: "400",
    fontFamily: " Poppins, Montserrat",
    color: theme.colors.text01,
    defaultComponent: "h3",
  },
  h4: {
    fontSize: "1.0625rem", // 17px
    lineHeight: "2rem", // 32px
    fontWeight: "700",
    fontFamily: " Poppins, Montserrat",
    color: theme.colors.text01,
    defaultComponent: "h4",
  },
  quote: {
    fontSize: "1.5rem", // 24px
    lineHeight: "2rem", // 32px
    fontWeight: "400",
    fontFamily: " Poppins, Montserrat",
    color: theme.colors.text02,
    defaultComponent: "p",
  },
  body1: {
    fontSize: "1.0625rem", // 17px
    lineHeight: "2rem", // 32px
    fontWeight: "400",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
  },
  body1Italic: {
    fontSize: "1.0625rem", // 17px
    lineHeight: "2rem", // 32px
    fontWeight: "400",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
    fontStyle: "italic",
  },
  body2: {
    fontSize: "0.9375rem", // 15px
    lineHeight: "2rem", // 32px
    fontWeight: "400",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
  },
  body2Italic: {
    fontSize: "0.9375rem", // 15px
    lineHeight: "2rem", // 32px
    fontWeight: "400",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
    fontStyle: "italic",
  },
  body2Bold: {
    fontSize: "0.9375rem", // 15px
    lineHeight: "2rem", // 32px
    fontWeight: "700",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
  },
  body3: {
    fontSize: "0.75rem", // 12px
    lineHeight: "1.5rem", // 24px
    fontWeight: "400",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
  },
  body3Bold: {
    fontSize: "0.75rem", // 12px
    lineHeight: "1.5rem", // 24px
    fontWeight: "700",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
  },
  body3Italic: {
    fontSize: "0.75rem", // 12px
    lineHeight: "1.5rem", // 24px
    fontWeight: "400",
    color: theme.colors.text02,
    fontFamily: " Poppins, Montserrat",
    fontStyle: "italic",
  },
  body3Spaced: {
    fontSize: "0.75rem", // 12px
    lineHeight: "1.5rem", // 24px
    fontWeight: "400",
    color: theme.colors.text02,
    letterSpacing: "0.125rem", // 2px
    fontFamily: " Poppins, Montserrat",
  },
  body3SpacedBold: {
    fontSize: "0.75rem", // 12px
    lineHeight: "1.5rem", // 24px
    fontWeight: "700",
    color: theme.colors.text02,
    letterSpacing: "0.125rem", // 2px
    fontFamily: " Poppins, Montserrat",
  },
};

const textOrPx = (x: string | number) => (typeof x === "string" ? x : `${x}px`);

const TypographySt = styled.p<TypographyProps>`
  margin: 0;
  color: ${({ variant }) => variant.color};
  font-size: ${({ variant }) => textOrPx(variant.fontSize)};
  font-weight: ${({ variant }) => variant.fontWeight};
  font-family: ${({ variant }) => variant.fontFamily};
  line-height: ${({ variant }) => textOrPx(variant.lineHeight)};
  font-style: ${({ variant }) => variant.fontStyle};
  letter-spacing: ${({ variant }) => variant.letterSpacing};
`;

export type UITypographyVariants =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "quote"
  | "body1"
  | "body1Italic"
  | "body2"
  | "body2Italic"
  | "body2Bold"
  | "body3"
  | "body3Bold"
  | "body3Italic"
  | "body3Spaced"
  | "body3SpacedBold";

export type UITypographyComponents =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span";

interface UITypographyProps {
  variant: UITypographyVariants;
  component?: UITypographyComponents;
  children: React.ReactNode;
  color?: string;
  fontFamily?: string;
  letterSpacing?: string;
  fontSize?: string;
  lineHeight?: string;
  fontStyle?: string;
  fontWeight?: number;
}

export const UITypography = (props: UITypographyProps) => {
  const defaultStyles = FontsVariantsMap[props.variant];

  const component: UITypographyComponents =
    props.component || FontsVariantsMap[props.variant].defaultComponent || "p";

  const variant: VariantTargetProps = {
    fontSize: props.fontSize || defaultStyles.fontSize,
    fontWeight: props.fontWeight || defaultStyles.fontWeight,
    lineHeight: props.lineHeight || defaultStyles.lineHeight,
    fontFamily: props.fontFamily || defaultStyles.fontFamily,
    letterSpacing: props.letterSpacing || defaultStyles.letterSpacing,
    color: props.color || defaultStyles.color,
    fontStyle: props.fontStyle || defaultStyles.fontStyle,
  };

  return (
    <TypographySt variant={variant} as={component}>
      {props.children}
    </TypographySt>
  );
};
