import React from 'react'
import styled from 'styled-components'

interface VariantStyles {
  height: string
}

interface SpacerStProps {
  spacerStyles: VariantStyles
}

const SpacerSt = styled.div<SpacerStProps>`
  height: ${({ spacerStyles }) => spacerStyles.height};
`

type Variants = 'xs' | 'sm' | 'reg' | 'lg' | 'xl'

const VariantStylesMap: Record<Variants, VariantStyles> = {
  xs: {
    height: '0.25rem', // 4px
  },
  sm: {
    height: '1.25rem', // 20px
  },
  reg: {
    height: '2.5rem', // 40px
  },
  lg: {
    height: '3.75rem', // 60px
  },
  xl: {
    height: '5rem', // 80px
  },
}

interface UISpacerProps {
  variant?: Variants
}

export const UISpacer = ({ variant = 'reg' }: UISpacerProps) => {
  const spacerStyles = VariantStylesMap[variant]

  return <SpacerSt spacerStyles={spacerStyles} />
}
