import { useReducer, useEffect } from 'react'

export const reducer = (
  currentSrc: string,
  action: { type: string; src: string }
) => {
  if (action.type === 'main image loaded') {
    return action.src
  }
  if (!currentSrc) {
    return action.src
  }
  return currentSrc
}

export const useProgressiveImage = ({
  src,
  fallbackSrc,
}: {
  src: string
  fallbackSrc: string
}) => {
  const [currentSrc, dispatch] = useReducer(reducer, '')

  useEffect(() => {
    const mainImage = new Image()
    const fallbackImage = new Image()

    mainImage.onload = () => {
      dispatch({ type: 'main image loaded', src })
    }
    fallbackImage.onload = () => {
      dispatch({ type: 'fallback image loaded', src: fallbackSrc })
    }

    mainImage.src = src
    fallbackImage.src = fallbackSrc
  }, [src, fallbackSrc])

  return currentSrc
}
