import { useMediaQuery } from 'react-responsive'
import { theme } from '../theme'

export const useResponsive = () => {
  const isSmallMobile = useMediaQuery({
    query: `(min-width: ${theme.breakpointPixels.xsm}px)`,
  })
  const isMobile = useMediaQuery({
    query: `(min-width: ${theme.breakpointPixels.sm}px)`,
  })
  const isTablet = useMediaQuery({
    query: `(min-width: ${theme.breakpointPixels.md}px)`,
  })
  const isSmallLaptop = useMediaQuery({
    query: `(min-width: ${theme.breakpointPixels.mlg}px)`,
  })
  const isLaptop = useMediaQuery({
    query: `(min-width: ${theme.breakpointPixels.lg}px)`,
  })
  const isMonitor = useMediaQuery({
    query: `(min-width: ${theme.breakpointPixels.xlg}px)`,
  })

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isSmallLaptop,
    isLaptop,
    isMonitor,
  }
}
